<template>
  <div>
    <SignpostForm
      v-if="version !== null && preloadCompleted"
      v-bind="{
        id,
        isInWebApp: isTest,
        flowType: 'sa',
        version,
        popupOpen,
        popup,
        showHidden: Boolean(currentPageId),
        startingPage,
      }"
      @testmode="isTest = $event"
    />
    <link
      v-for="f in fontFamilies"
      :key="f"
      :href="`https://fonts.googleapis.com/css2?${f}&amp;display=swap`"
      rel="stylesheet"
      type="text/css"
    />
    <div v-if="popup" class="popup-opener">Open Popup</div>
  </div>
</template>

<script>
import Vue from 'vue'
import Firebase from 'firebase/app'
import 'firebase/firestore'

import '@/helpers/prototypes'
import '@/boot-up/jitsu'
import detectLocalStorage from '@/helpers/detectLocalStorage'
import googleFontsUrls from '@/helpers/googleFontsUrls'
import getUrlDict from '@/helpers/getUrlDict'
import { loadGooglePlaces } from '@/boot-up/scriptLoading'
import SignpostForm from '@/components/form/SignpostForm'

if (Firebase.apps.length === 0) {
  var firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSENGER_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  }

  // Initialize Firebase
  Firebase.initializeApp(firebaseConfig)
}

Vue.config.productionTip = false
Vue.config.performance = (detectLocalStorage() && localStorage.SavvyDebugMode) || false
Vue.config.devtools = (detectLocalStorage() && localStorage.SavvyDebugMode) || false

export default {
  name: 'Flow',
  components: {
    SignpostForm,
  },
  props: {
    id: String,
  },
  data() {
    return {
      flowDoc: null,
      isTest: true,
      latestVersion: null,
      popup: false,
      popupOpen: false,
      currentPageId: null,
      currentPageIndex: null,
      currentPageKey: null,
      preloadCompleted: false,
    }
  },
  computed: {
    version() {
      const dict = getUrlDict()
      const version = dict.version
      switch (version) {
        case 'prod':
        case 'live':
          return undefined
        case 'latest':
          return typeof this.latestVersion === 'number' ? this.latestVersion : null
        default:
          return Number(version) || undefined
      }
    },
    flow() {
      if (this.flowDoc) return JSON.parse(this.flowDoc.form || '{}')
      return null
    },
    fontFamilies() {
      const styles = (this.flow && this.flow.styles) || {}
      return googleFontsUrls(styles)
    },
    flowComponents() {
      const flowPages = (this.flow && this.flow.pages) || []
      const pages =
        flowPages.map(page => ({
          ...page,
          components: (page.components || []).map(b =>
            b.buttons ? { ...b, buttons: b.buttons } : b
          ),
        })) || []

      const components = pages.reduce((acc, p) => {
        p.components.forEach(c => acc.push(c))
        return acc
      }, [])

      return components
    },
    startingPage() {
      if (this.currentPageId) return { type: 'id', value: this.currentPageId }
      if (this.currentPageIndex) return { type: 'index', value: this.currentPageIndex }
      if (this.currentPageKey) return { type: 'key', value: this.currentPageKey }
      return null
    },
  },
  watch: {
    id: {
      async handler(id) {
        if (!id) return
        this.preloadCompleted = false
        const flowRef = Firebase.firestore()
          .collection('forms')
          .doc(id)
        try {
          const versionSnap = await flowRef
            .collection('history')
            .orderBy('version', 'desc')
            .limit(1)
            .get()
          if (versionSnap && versionSnap.docs && versionSnap.docs[0]) {
            try {
              this.latestVersion = versionSnap.docs[0].get('version')
            } catch (error) {
              console.error(error)
            }
          }

          const flowVersionRef =
            typeof this.version === 'number'
              ? flowRef.collection('history').doc(`version-${this.version}`)
              : flowRef
          const flowDoc = await flowVersionRef.get()
          const flow = { form: flowDoc.get('form') }
          const dict = getUrlDict()
          if (dict.popup === 'true') this.popup = true
          if (dict.current_page_id) this.currentPageId = dict.current_page_id
          if (dict.current_page_index) this.currentPageIndex = dict.current_page_index
          if (dict.current_page_key) this.currentPageKey = dict.current_page_key
          try {
            let title = flowDoc.get('title')
            const parsedFlow = JSON.parse(flow.form)
            if (parsedFlow.standalone_full_height) {
              document.querySelector('html').style.height = '100%'
              document.querySelector('body').style.height = '100%'
              document.querySelector('body > div').style.height = '100%'
            }
            if (!title) title = parsedFlow.title
            document.title = title || 'Savvy Flows'
            if (parsedFlow.popup) this.popup = parsedFlow.popup
          } catch (error) {
            console.error(error)
          }
          this.flowDoc = flow
          document.querySelectorAll('savvy-smart-form').forEach(e => e.remove())
          if (this.$route && this.$route.query && this.$route.query.no_analytics) {
            this.preloadCompleted = true
            return
          }
          try {
            const productId = flowDoc.get('primaryProductId')
            if (productId) {
              const savvySmartForm = document.createElement('savvy-smart-form')
              savvySmartForm.id = productId
              document.body.appendChild(savvySmartForm)
            }
          } catch (error) {
            console.error(error)
          }
        } catch (error) {
          console.error(error)
        }
        this.preloadCompleted = true
      },
      immediate: true,
    },
    flowComponents: {
      handler(c) {
        const needsPlacesScript = c.find(
          co =>
            co.fetch_options &&
            co.fetch_preset === 'location' &&
            co.fetch_preset_location_type === 'places_autocomplete'
        )
        if (needsPlacesScript) {
          loadGooglePlaces()
        }
      },
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/flows.scss';
</style>
