var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.version !== null && _vm.preloadCompleted)?_c('SignpostForm',_vm._b({on:{"testmode":function($event){_vm.isTest = $event}}},'SignpostForm',{
      id: _vm.id,
      isInWebApp: _vm.isTest,
      flowType: 'sa',
      version: _vm.version,
      popupOpen: _vm.popupOpen,
      popup: _vm.popup,
      showHidden: Boolean(_vm.currentPageId),
      startingPage: _vm.startingPage,
    },false)):_vm._e(),_vm._l((_vm.fontFamilies),function(f){return _c('link',{key:f,attrs:{"href":("https://fonts.googleapis.com/css2?" + f + "&display=swap"),"rel":"stylesheet","type":"text/css"}})}),(_vm.popup)?_c('div',{staticClass:"popup-opener"},[_vm._v("Open Popup")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }